import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Code = makeShortcode("Code");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`In order for a button’s purpose to be clear to all users, it must have a meaningful name.`}</p>
    <h3>{`What is a meaningful name?`}</h3>
    <p>{`A meaningful name describes the button’s purpose: the action that occurs when the button is activated (for example: removing a list item), and the action’s associated context (for example: the specific item to be removed). To avoid unnecessary verbosity, button names should be as succinct as possible while still being descriptive and unique.`}</p>
    <p>{`Refer to `}<a parentName="p" {...{
        "href": "#identifying-github-comments"
      }}>{`Identifying GitHub comments`}</a>{` below for an example of a succinct, unique name. To explore how meaningful names can be set in code, refer to the `}<a parentName="p" {...{
        "href": "#examples"
      }}>{`examples`}</a>{` below.`}</p>
    <h3>{`Why is a meaningful name necessary?`}</h3>
    <p>{`Clear and consistent labels set user expectations for button actions, giving users confidence that activating a button will have the outcome they expect.`}</p>
    <p>{`Screen readers (for example: VoiceOver) provide overlays to enable users to jump to specific types of elements, including buttons. Elements are listed by their names. When buttons don’t have meaningful names, it’s not possible to determine which action will be performed when selecting them from the list.`}</p>
    <h2>{`How to test names`}</h2>
    <p>{`When reviewing buttons on a page, ensure the following are true:`}</p>
    <ul>
      <li parentName="ul">{`Each button has a non-empty name. Refer to `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/standards-guidelines/act/rules/97a4e1/"
        }}>{`ACT 97a4e1: Button has non-empty accessible name`}</a>{`.`}</li>
      <li parentName="ul">{`When buttons perform the same action, they have the same name.`}</li>
      <li parentName="ul">{`When buttons perform different actions, they don’t have the same name.`}</li>
    </ul>
    <h2>{`Guidelines`}</h2>
    <h3>{`For designers`}</h3>
    <ul>
      <li parentName="ul">{`When including an unlabeled, icon-only button in a design, recommend an accessible name.`}</li>
      <li parentName="ul">{`When including multiple buttons that peform the same function in a design, use the same label for each.`}</li>
    </ul>
    <h3>{`For engineers`}</h3>
    <ul>
      <li parentName="ul">{`When a button doesn’t have a visible label (for example: an `}<a parentName="li" {...{
          "href": "https://primer.style/view-components/components/iconbutton"
        }}>{`IconButton`}</a>{`), provide an accessible name. Refer to `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA14"
        }}>{`ARIA 14: Using `}<inlineCode parentName="a">{`aria-label`}</inlineCode>{` to provide an invisible label where a visible label cannot be used`}</a>{`.`}</li>
      <li parentName="ul">{`Because `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` doesn’t `}<em parentName="li">{`supplement`}</em>{` visible labels but rather `}<em parentName="li">{`supplants`}</em>{` them, when a button has a visible label, include that label in its accessible name. A good practice is to have the text of the label at the start of the name.`}</li>
      <li parentName="ul">{`Don’t use `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` when its content would be identical to a button’s visible label.`}</li>
      <li parentName="ul">{`Don’t reuse the same (visible) label or (invisible) name for buttons which perform different actions.`}</li>
    </ul>
    <h2>{`Examples`}</h2>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Code className="language-html" mdxType="Code">{`<button type="button">OK</button>`}</Code>
    <Caption mdxType="Caption">The button does not have a redundant `aria-label` attribute.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Code className="language-html" mdxType="Code">{`<button type="button" aria-label="OK">OK</button>`}</Code>
    <Caption mdxType="Caption">The button has a redundant and unnecessary `aria-label` attribute.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Code className="language-html" mdxType="Code">
      {`<button type="button" aria-label="smockle’s profile"><img alt="smockle" src="https://avatars.githubusercontent.com/u/3104489?s=32"></button>`}
    </Code>
    <Caption mdxType="Caption">The button should have an accessible name.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Code className="language-html" mdxType="Code">
      {`<button type="button"><img src="https://avatars.githubusercontent.com/u/3104489?s=32"></button>`}
    </Code>
    <Caption mdxType="Caption">The button should not be missing a name.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Meaningful and unique names`}</h3>
    <p>{`Each button in the example below has a unique name which describes its action (“Remove”) and its action’s context (for example: “'Apples'”).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-HTML"
      }}>{`<script>
  function removeItem(event) {
    const item = event.target.closest("li");
    item?.parentNode.removeChild(item);
  }
</script>
<ul>
  <li>Apples <button onclick="removeItem(event)" aria-label="Remove 'Apples'" type="button">❌</button></li>
  <li>Bananas <button onclick="removeItem(event)" aria-label="Remove 'Bananas'" type="button">❌</button></li>
  <li>Cantaloupes <button onclick="removeItem(event)" aria-label="Remove 'Cantaloupes'" type="button">❌</button></li>
</ul>
`}</code></pre>
    <p>{`Although each button in the example below performs a different action, they all have the same name: ❌ (“cross mark”). This name does not describe the action that occurs when a given button is activated, nor does it describe the action’s context.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-HTML"
      }}>{`<script>
  function removeItem(event) {
    const item = event.target.closest("li");
    item?.parentNode.removeChild(item);
  }
</script>
<ul>
  <li>Apples <button onclick="removeItem(event)" type="button">❌</button></li>
  <li>Bananas <button onclick="removeItem(event)" type="button">❌</button></li>
  <li>Cantaloupes <button onclick="removeItem(event)" type="button">❌</button></li>
</ul>
`}</code></pre>
    <h2>{`Additional resources`}</h2>
    <h3>{`Terminology: “label” vs “name”`}</h3>
    <p>{`When you review the Web Content Accessibility Guidelines (WCAG) below, you’ll encounter the terms “label” and “name”. Here are the definitions given for each:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#dfn-labels"
          }}>{`label`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`text or other component with a text alternative that is presented to a user to identify a component within Web content`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#dfn-name"
          }}>{`name`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`text by which software can identify a component within Web content to the user (Note: This is unrelated to the `}<inlineCode parentName="p">{`name`}</inlineCode>{` attribute in HTML.)`}</p>
        </blockquote>
      </li>
    </ul>
    <p>{`The name may be hidden and only exposed by assistive technology, whereas a label is presented to all users. In many (but not all) cases, the label and the name are the same.`}</p>
    <h3>{`Related WCAG guidelines and success criteria`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#identify-purpose"
          }}>{`SC 1.3.6 — Identify Purpose`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`…the purpose of user interface components…can be programmatically determined.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#link-purpose-in-context"
          }}>{`SC 2.4.4 — Link Purpose (In Context)`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`The purpose of each link can be determined from the link text alone or from the link text together with its programmatically determined link context…`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#link-purpose-link-only"
          }}>{`SC 2.4.9 — Link Purpose (Link Only)`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`A mechanism is available to allow the purpose of each link to be identified from link text alone…`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#label-in-name"
          }}>{`SC 2.5.3 — Label in Name`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`For user interface components with labels that include text…the name contains the text that is presented visually.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#consistent-identification"
          }}>{`SC 3.2.4 — Consistent Identification`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Components that have the same functionality within a set of Web pages are identified consistently.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG22/#name-role-value"
          }}>{`SC 4.1.2 — Name, Role, Value`}</a>{`:`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`For all user interface components…the name and role can be programmatically determined…`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Related patterns and shared code`}</h3>
    <h4>{`Identifying GitHub comments`}</h4>
    <p>{`When a button is associated with a specific comment, a comment indentifier should be included within the button’s name. `}<a parentName="p" {...{
        "href": "https://github.com/github/github/blob/016b875e0542c0781a3c6f5ed27c58c472cc6717/app/components/reactions/reactions_component.rb#L47-L51"
      }}><inlineCode parentName="a">{`reaction_target_identifier`}</inlineCode></a>{` (only accessible to GitHub staff) uses `}<a parentName="p" {...{
        "href": "https://github.com/github/github/blob/9bea7b7d0d120df6b9b864fda39fe374479acc0d/app/components/application_component.rb#L68-L78"
      }}><inlineCode parentName="a">{`aria-label-date`}</inlineCode></a>{` (only accessible to GitHub staff) to generate a concise, unique comment identifier.`}</p>
    <p>{`For example, `}<inlineCode parentName="p">{`reaction_target_identifier`}</inlineCode>{` could be used in a reply-to-comment button’s name. In all cases, `}<inlineCode parentName="p">{`reaction_target_identifier`}</inlineCode>{` adds the comment’s author and timestamp; conditionally, as needed, `}<inlineCode parentName="p">{`reaction_target_identifier`}</inlineCode>{` adds progressively-verbose date information:`}</p>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`Reply to benjiallen, 2:45PM today`}</li>
        <li parentName="ul">{`Reply to benjiallen, 2:45PM yesterday`}</li>
        <li parentName="ul">{`Reply to benjiallen, 2:45PM on November 19`}</li>
        <li parentName="ul">{`Reply to benjiallen, 2:45PM on November 19, 2021`}</li>
      </ul>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      